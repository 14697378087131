/* eslint-disable camelcase */
export default class Medium {
  description;
  library_id;
  name;

  constructor() {
    this.description = "";
    this.library_id = null;
    this.name = "";
  }
}