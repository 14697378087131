<script>
import mediaServices from '@/services/media.service'
import libraryService from '@/services/library.service'
import Medium from '@/data/models/Medium'
import EditLayout from '@/layout/EditLayout'

export default {
  name: 'SingleMedium',
  components: {
    EditLayout
  },
	data() {
		return {
      item: new Medium,
      photo: null,
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    !this.isNew && this.id && this._get()
	},
	methods: {
    _get(type = null) {
      mediaServices.getById({ id: this.id })
      .then(res => {
        this.item = res.data
        res.data.library_id && this._getPhoto({ id: res.data.library_id })
      })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    create() {
      mediaServices.create({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('medium') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this.$router.push({ name: 'media' })
        })
    },
    edit() {
      mediaServices.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('medium') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
	}
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :photo="photo"
    supports-photo
    @submit="isNew ? create() : edit()"
  />
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
